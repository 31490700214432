
// Button
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit']
  background-color: $color-primary
  border: .1rem solid $color-primary
  border-radius: .4rem
  color: $color-initial
  cursor: pointer
  display: inline-block
  font-size: 1.1rem
  font-weight: 700
  height: 3.8rem
  letter-spacing: .1rem
  line-height: 3.8rem
  padding: 0 3.0rem
  text-align: center
  text-decoration: none
  text-transform: uppercase
  white-space: nowrap

  &:focus,
  &:hover
    background-color: $color-secondary
    border-color: $color-secondary
    color: $color-initial
    outline: 0

  &[disabled]
    cursor: default
    opacity: .5

    &:focus,
    &:hover
      background-color: $color-primary
      border-color: $color-primary

  &.button-outline
    background-color: transparent
    color: $color-primary

    &:focus,
    &:hover
      background-color: transparent
      border-color: $color-secondary
      color: $color-secondary

    &[disabled]

      &:focus,
      &:hover
        border-color: inherit
        color: $color-primary

  &.button-clear
    background-color: transparent
    border-color: transparent
    color: $color-primary

    &:focus,
    &:hover
      background-color: transparent
      border-color: transparent
      color: $color-secondary

    &[disabled]

      &:focus,
      &:hover
        color: $color-primary
